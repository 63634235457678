<template>
  <v-app id="app" class="app" v-show="!$root.platform.prerenderer && isReady">

    <!-- this is a header of the site -->
    <Header></Header>
    <v-main>
      <router-view v-if="isReady" />
    </v-main>

    <VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
      />
  </v-app>
</template>

<script>
import Header from './molecules/Header.vue'
import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import shareImage from '@/assets/img/share.jpg'
let initializedModernizr = false

export default {
  name: 'App',

  components: {
    VersionInfo, Header
  },

  data: function () {
    return {
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false
    }
  },

  mounted: function () {
    this.tracked = false
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later
    this.isReady = true
    // this.checkQuery()
    // console.log('origin', this.origin)
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },

  methods: {
    checkQuery () {
      console.log('params', this.$route.query)
      if (!this.tracked && this.$gtag) {
        this.tracked = true
        const s = this.$route.query.source || 'direct'
        this.$gtag.event('landing', { source: s })
        console.log('track landing', s)
      }
    }
  },
  watch: {
    isReady: function (value) {
      // hide / show preloader
      this.$root.preloader.visible = !value
    },
    $route () {
      this.checkQuery()
    }
  },

  metaInfo: function () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: ' ',
      // all titles will be injected into this template
      titleTemplate: 'BVLGARI',
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@bulgariofficial' },
        { name: 'twitter:creator', content: '@bulgariofficial' },
        // generic description
        { vmid: 'description', name: 'description', content: 'BVLGARI' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/' + this.$route.fullPath },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'BVLGARI' },
        { vmid: 'ogdescription', property: 'og:description', content: 'BVLGARI' },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + shareImage },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '630' }
      ],
      htmlAttrs: {
        lang: this.$language.current
      }
    }
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: 'Bulgari';
  font-style: normal;
  font-weight: 700;
  src:
    url('assets/fonts/BulgariType-Bold.woff2') format('woff2'),
    url('assets/fonts/BulgariType-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Bulgari';
  font-style: normal;
  font-weight: 400;
  src:
    url('assets/fonts/BulgariType-Regular.woff2') format('woff2'),
    url('assets/fonts/BulgariType-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Bulgari';
  font-style: normal;
  font-weight: 300;
  src:
    url('assets/fonts/BulgariType-Light.woff2') format('woff2'),
    url('assets/fonts/BulgariType-Light.woff') format('woff');
}

@font-face {
  font-family: 'Bulgari';
  font-style: italic;
  font-weight: 700;
  src:
    url('assets/fonts/BulgariType-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/BulgariType-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Bulgari';
  font-style: italic;
  font-weight: 400;
  src:
    url('assets/fonts/BulgariType-Italic.woff2') format('woff2'),
    url('assets/fonts/BulgariType-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Bulgari';
  font-style: italic;
  font-weight: 300;
  src:
    url('assets/fonts/BulgariType-LightItalic.woff2') format('woff2'),
    url('assets/fonts/BulgariType-LightItalic.woff') format('woff');
}

html,
body {
  // overflow: hidden !important;

  .theme--light.v-application {
    color: $c-black;
    font-family: 'Bulgari';
    letter-spacing: normal;
    line-height: normal;
    font-weight: 300;
    // overflow-y: scroll;
  }
}

a {
  text-decoration: none;
}

p {
  font-size: 18px;
  line-height: 1.67;

  @include breakpoint('md-and-down') {
    font-size: 14px;
  }
}

h1 {
  font-size: 72px;

  @include breakpoint('lg-and-down') {
    font-size: 50px;
    line-height: 0.9;
  }

  @include breakpoint('md-and-down') {
    font-size: 36px;
    line-height: 0.9;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }
}

h2 {
  font-size: 60px;

  @include breakpoint('md-and-down') {
    font-size: 48px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }

  @include breakpoint('xs-only') {
    font-size: 28px;
  }
}

h3 {
  font-size: 48px;

  @include breakpoint('md-and-down') {
    font-size: 36px;
  }
}

h4 {
  font-size: 24px;
  letter-spacing: normal;

  @include breakpoint('md-and-down') {
    font-size: 22px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 20px;
  }
}

h5 {
  font-size: 16px;

  @include breakpoint('md-and-down') {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
}

span {
  font-size: 12px;
}

strong {
  font-weight: bold !important;
}

.display-none {
  display: none;
}

.line-height-15 {
  line-height: 1.5;
}

.svg-fill-white {
  fill: $c-white;

  path {
    fill: $c-white;
  }
}

.svg-stroke-white {
  stroke: $c-white;

  path {
    stroke: $c-white;
  }
}

.svg-fill-none {
  fill: none;

  path {
    fill: none;
  }
}

.svg-fill-black {
  fill: $c-black;

  path {
    fill: $c-black;
  }
}

.svg-stroke-black {
  stroke: $c-black;

  path {
    stroke: $c-black;
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-100 {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.extra-light {
  font-weight: 100;
}

.inline-block {
  display: inline-block;
  vertical-align: middle;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-leave-active,
.v-enter-active {
  transition: opacity 1s $ease-out-quint;
}

.v-enter,
.v-leave-to {
  opacity: 0 !important;
}

.slide-leave-active,
.slide-enter-active {
  transition: transform 0.5s, opacity 0.5s $ease-out-quint;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
