import Vue from 'vue'
import VueGtag from 'vue-gtag'
// import router from '@/plugins/router'
import Platform from '@monogrid/js-utils/lib/Platform'

const isTesting = window.location.href.indexOf('localhost') !== -1 ||
                window.location.href.indexOf('-develop') !== -1 ||
                window.location.href.indexOf('-master') !== -1

// only if no prerenderer
if (!Platform.prerenderer && !isTesting) {
  // usage: https://github.com/MatteoGabriele/vue-analytics
  Vue.use(VueGtag, {
    config: { id: 'G-GNXEDRX3T6' }
  })
}
