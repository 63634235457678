<template>
  <div class="header mt-6">
      <logo class="logo"></logo>
    </div>
</template>

<script>
import logo from '@/assets/svg/logo.svg'

export default {
  components: {
    logo
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  text-align: center;
}
</style>
